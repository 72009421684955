import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import TicketMap from './TicketMap';
import TicketList from './TicketList';
import TechnicianTickets from './TechnicianTickets';
import MapSupport from './MapSupport';
import TicketTitleGenerator from './TitleBuilder';
import './App.css';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  // Menü öffnen beim Hover
  const handleMouseEnter = () => {
    setMenuOpen(true);
  };

  // Menü schließen, wenn die Maus den Bereich verlässt
  const handleMouseLeave = () => {
    setMenuOpen(false);
  };

  return (
    <Router>
      <div className="app">
        <header className="app-header">
          {/* Burger-Symbol bleibt immer sichtbar */}
          <button 
            className="burger-menu" 
            onMouseEnter={handleMouseEnter} // Menü anzeigen beim Hover über das Burger-Symbol
          >
            ☰
          </button>

          {/* Das Menü wird nur angezeigt, wenn der Mauszeiger über dem Burger-Symbol oder dem Menü ist */}
          <nav 
            className={`menu ${menuOpen ? 'menu-open' : ''}`}  // Die Klasse 'menu-open' zeigt das Menü an
            onMouseLeave={handleMouseLeave}  // Menü verstecken, wenn die Maus das Menü verlässt
            onMouseEnter={handleMouseEnter}  // Sicherstellen, dass das Menü sichtbar bleibt, solange die Maus darüber ist
          >
            <ul>
              <li><Link to="/" onClick={() => setMenuOpen(false)}>Karte</Link></li>
              <li><Link to="/liste" onClick={() => setMenuOpen(false)}>Liste</Link></li>
              <li><Link to="/techniker" onClick={() => setMenuOpen(false)}>Techniker</Link></li>
            </ul>
          </nav>
        </header>

        <main>
          <Routes>
<Route path="/stats" element={<MapSupport />} />
            <Route path="/" element={<TicketMap />} />
            <Route path="/liste" element={<TicketList />} />
            <Route path="/techniker" element={<TechnicianTickets />} />
<Route path="/gen" element={<TicketTitleGenerator />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
