import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosConfig'; // Verwende die konfigurierte Axios-Instanz
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap für Styles
import moment from 'moment'; // Für Zeitberechnungen
import './TicketList.css'; // Eigene CSS-Datei für Animationen und Styles
import BackToMenuButton from './BackToMenuButton'; // Import the button


function TicketList() {
  const [tickets, setTickets] = useState([]); // Originaldaten aller Tickets
  const [filteredTickets, setFilteredTickets] = useState([]); // Gefilterte und sortierte Tickets
  const [loading, setLoading] = useState(true); // Ladeanzeige
  const [searchTerm, setSearchTerm] = useState(''); // Suchbegriff für die gesamte Tabelle
  const [filters, setFilters] = useState({}); // Filter für die Dropdowns
  const [slaFilter, setSlaFilter] = useState(''); // SLA-Filter
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'none' }); // **Sortierkonfiguration**
  const originalTickets = React.useRef([]); // Originalreihenfolge beibehalten

  // Tickets beim Laden der Komponente von der API abrufen
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axiosInstance.get('/api/tickets'); // API-Endpunkt
        setTickets(response.data); // Setze die Originaldaten
        setFilteredTickets(response.data); // Initial alle Tickets anzeigen
        originalTickets.current = response.data; // Speichere die Originaldaten
        setLoading(false); // Ladevorgang abgeschlossen
      } catch (error) {
        console.error('Fehler beim Laden der Tickets:', error);
        setLoading(false); // Ladevorgang abgeschlossen, auch bei Fehler
      }
    };
    fetchTickets();
  }, []);

  // Filtert die Tickets basierend auf dem Suchbegriff und SLA-Filter
  useEffect(() => {
    let filtered = [...tickets]; // Arbeite immer auf einer Kopie der Originaltickets
    const now = moment();

    if (searchTerm) {
      filtered = filtered.filter((ticket) => {
        return Object.values(ticket).some((value) => {
          return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        });
      });
    }

    // SLA-Filter anwenden
    if (slaFilter) {
      filtered = filtered.filter((ticket) => {
	
        const slaMoment = moment(ticket.sla);
        const createTimeMoment = moment(ticket.createTime);
	console.log(ticket.id + " - " + slaMoment.format('YYYY-MM-DD HH:mm:ss') + " " + createTimeMoment.format('YYYY-MM-DD HH:mm:ss'));        
switch (slaFilter) {
          case 'today':
            return slaMoment.isSame(moment(), 'day');
          case '12hours':
            return slaMoment.isBetween(moment(), moment().add(24, 'hours'));
          case 'tomorrow':
            return slaMoment.isSame(moment().add(1, 'day'), 'day');
          case 'expired':
            return slaMoment.isBefore(moment()) && slaMoment.isAfter(moment().subtract(3, 'days'));
          case 'last24hours':
console.log(ticket.id + " - " + slaMoment.format('YYYY-MM-DD HH:mm:ss') + " " + createTimeMoment.format('YYYY-MM-DD HH:mm:ss'));     
		console.log(moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss') + "  ---  " + moment().format('YYYY-MM-DD HH:mm:ss'));
            return createTimeMoment.isBetween(moment().subtract(1, 'day'), moment());
case 'vienna': return (ticket.techniker == 'Denis Jovanovic'|| ticket.techniker == 'Rene Spörk' || ticket.techniker == 'Kilian Kahn' || ticket.techniker == 'Norbert Szabo' || ticket.techniker == 'Elvir Kostandinovic')
case 'viennanow':  return slaMoment.isSame(moment(), 'day') && (ticket.techniker == 'Rene Spörk' || ticket.techniker == 'Denis Jovanovic' || ticket.techniker == 'Kilian Kahn' || ticket.techniker == 'Norbert Szabo' || ticket.techniker == 'Elvir Kostandinovic')          

default:
            return true;
        }
      });
    }

    // Filter auf Grundlage der Dropdown-Auswahl anwenden
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtered = filtered.filter(ticket => ticket[key]?.toString().toLowerCase() === filters[key].toLowerCase());
      }
    });

    setFilteredTickets(filtered);
  }, [searchTerm, filters, slaFilter, tickets]);

  // Sortierfunktion
  const sortTickets = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      setFilteredTickets([...originalTickets.current]); // Keine Sortierung
      setSortConfig({ key: null, direction: 'none' });
      return;
    }
    const sortedTickets = [...filteredTickets].sort((a, b) => {
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      return 0;
    });
    setFilteredTickets(sortedTickets);
    setSortConfig({ key, direction });
  };

  // Sortiersymbol für jede Spalte anzeigen
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'asc') return ' ▲';
      if (sortConfig.direction === 'desc') return ' ▼';
    }
    return '';
  };

  // Berechnet die verbleibende Zeit für die SLA und bestimmt die Farbe und Animation
  const getSlaInfo = (sla) => {
    const now = moment();
    const slaMoment = moment(sla);
    const diffInMinutes = slaMoment.diff(now, 'minutes');
    const hours = Math.floor(Math.abs(diffInMinutes) / 60);
    const minutes = Math.abs(diffInMinutes % 60);
    let color = 'black';
    let rowStyle = {};
    let animationClass = '';

    if (diffInMinutes < 0) {
      color = 'blue'; // In der Vergangenheit
    } else if (diffInMinutes <= 60) {
      color = 'red'; // Weniger als 1 Stunde in der Zukunft
      animationClass = 'pulse-fast';
      rowStyle = { backgroundColor: '#ffe5e5' }; // Hellroter Hintergrund für die gesamte Zeile
    } else if (diffInMinutes <= 120) {
      color = '#cc5500'; // Dunkleres Orange für weniger als 2 Stunden
      animationClass = 'pulse-fast';
      rowStyle = { backgroundColor: '#fff5e6' }; // Hellorange Hintergrund für die gesamte Zeile
    } else if (diffInMinutes <= 300) {
      color = '#666600'; // Dunkleres Gelb für weniger als 5 Stunden
      animationClass = 'pulse-fast';
      rowStyle = { backgroundColor: '#ffffe0' }; // Hellgelber Hintergrund für die gesamte Zeile
    }

    return { 
      timeString: (diffInMinutes < 0 ? '-' : '') + `${hours}h ${minutes}m`,
      color,
      animationClass,
      rowStyle,
      originalSla: slaMoment.format('YYYY-MM-DD HH:mm')
    };
  };

  // Handler für Dropdown-Filter
  const handleFilterChange = (key, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
  };

  // Handler für SLA-Filter
  const handleSlaFilterChange = (e) => {
    setSlaFilter(e.target.value);
  };

  // Handler für Refresh-Button, um einen POST-Request zu senden
  const handleRefresh = async (id) => {
    try {
      await axiosInstance.post(`/api/ticket/${id}`);
      console.log(`Ticket ${id} wurde erfolgreich aktualisiert.`);
    } catch (error) {
      console.error(`Fehler beim Aktualisieren von Ticket ${id}:`, error);
    }
  };

  return (
    <div className="container mt-4">
  <div>
      <BackToMenuButton />    </div>
      <h2>Ticket List</h2>

      {/* Filter Dropdowns */}
      <div className="row mb-3">
        <div className="col">
          <select className="form-control" onChange={(e) => handleFilterChange('techniker', e.target.value)}>
            <option value="">Filter Techniker</option>
            {Array.from(new Set(tickets.map(ticket => ticket.techniker))).map(techniker => (
              <option key={techniker} value={techniker}>{techniker}</option>
            ))}
          </select>
        </div>
        <div className="col">
          <select className="form-control" onChange={(e) => handleFilterChange('queue', e.target.value)}>
            <option value="">Filter Queue</option>
            {Array.from(new Set(tickets.map(ticket => ticket.queue))).map(queue => (
              <option key={queue} value={queue}>{queue}</option>
            ))}
          </select>
        </div>
      </div>

      {/* SLA Filter */}
      <div className="row mb-3">
        <div className="col">
          <select className="form-control" value={slaFilter} onChange={handleSlaFilterChange}>
            <option value="">SLA Filter</option>
            <option value="today">Heute</option>
            <option value="12hours">12 Stunden</option>
            <option value="tomorrow">Morgen</option>
            <option value="expired">Aktuell abgelaufen (max 3 Tage)</option>
            <option value="last24hours">Erstellt in den letzten 24 Stunden</option>
	<option value="vienna" style={{ backgroundColor: '#FFD580' }}>Interne Techniker</option> 
 <option value="viennanow">Interne Techniker - Heute</option>              
 </select>
        </div>
      </div>

      {/* Suchfeld unterhalb der Filter */}
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Suche in allen Feldern..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Ladeanimation */}
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="sortable" onClick={() => sortTickets('id')}>
                ID {getSortIndicator('id')}
              </th>
              <th className="sortable" onClick={() => sortTickets('status')}>
                Status {getSortIndicator('status')}
              </th>
              <th className="sortable" onClick={() => sortTickets('titel')}>
                Titel {getSortIndicator('titel')}
              </th>
              <th className="sortable" onClick={() => sortTickets('plz')}>
                PLZ {getSortIndicator('plz')}
              </th>
              <th className="sortable" onClick={() => sortTickets('techniker')}>
                Techniker {getSortIndicator('techniker')}
              </th>
              <th className="sortable" onClick={() => sortTickets('sla')}>
                SLA (Time Left) {getSortIndicator('sla')}
              </th>
 <th className="sortable" onClick={() => sortTickets('sladate')}>
                Fällig am {getSortIndicator('sladate')}
              </th>
              <th>Refresh</th>
              <th>OTRS</th>
 {/*     
          <th>Übertragen</th>
              <th>Maps</th>
              <th>Abmelden</th>
*/}     
       </tr>
          </thead>
          <tbody>
            {filteredTickets.map((ticket) => {
              const { timeString, color, animationClass, rowStyle, originalSla } = getSlaInfo(ticket.sla);
              return (
                <tr key={ticket.id} style={rowStyle}>
                  <td>{ticket.id}</td>
                  <td>{ticket.status}</td>
                  <td>{ticket.titel}</td>
                  <td>{ticket.plz}</td>
                  <td>{ticket.techniker}</td>
                  <td className={animationClass} style={{ color }}>
                    <span>{timeString}</span>
                  </td>
 <td className={animationClass} style={{ color }}>
                    <small>{originalSla}</small>
                  </td>
                  <td>
                    <span
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                      onClick={() => handleRefresh(ticket.id)}
                    >
                      🔄
                    </span>
                  </td>
                  <td>
                    <a
                      href={`https://service.danubix.com/otrs/index.pl?Action=AgentTicketZoom;TicketID=${ticket.id}`}
                      target="_blank"
                      className="btn btn-info btn-sm"
                    >
                      OTRS
                    </a>
                  </td>
{/*
                  <td>
                    <a
                      href={`https://service.danubix.com/otrs/index.pl?Action=AgentTicketAddtlITSMField;TicketID=${ticket.id}`}
                      target="_blank"
                      className="btn btn-warning btn-sm"
                    >
                      Übertragen
                    </a>
                  </td>
                  <td>
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(ticket.address)}`}
                      target="_blank"
                      className="btn btn-success btn-sm"
                    >
                      Maps
                    </a>
                  </td>
                  <td>
                    <a
                      href={`https://service.danubix.com/otrs/index.pl?Action=AgentTicketClose;TicketID=${ticket.id}`}
                      target="_blank"
                      className="btn btn-danger btn-sm"
                    >
                      Abmelden
                    </a>
                  </td>
*/}   
             </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default TicketList;
